<template>
  <vs-row>
    <vs-col class="mb-3">
      <h3 class="mb-3">
        {{ $t('catalog.header') }}
      </h3>
      <p>{{ $t('catalog.description') }} <a
        target="_blank"
        href=""
      >{{ $t('catalog.here') }}</a></p>
    </vs-col>
  </vs-row>
</template>
<script>

export default {
  name: 'ConfigurationHeader',
}
</script>
