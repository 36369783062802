



















import { LocaleMessages } from 'vue-i18n'
import Vue from 'vue'
import moduleCatalog from '@/store/catalog/moduleCatalog'

export default Vue.extend({
  name: 'InputCatalogName',
  computed: {
    name: {
      get(): string {
        return moduleCatalog.getters.getCatalogName
      },
      set(value: string) {
        moduleCatalog.dispatch('setCatalogName', value)
      },
    },
    labelCatalogName(): string | LocaleMessages {
      return this.$t('catalog.provideName')
    },
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
