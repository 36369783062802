<template>
  <vs-col>
    <WizardErrors />
    <FormWizard
      ref="catalogCreatorWizard"
      :title="null"
      :subtitle="null"
      :next-button-text="$t('catalogWizard.next')"
      :back-button-text="$t('catalogWizard.previous')"
      :finish-button-text="$t('catalogWizard.save')"
      :start-index="0"
      layout="horizontal"
      shape="circle"
      step-size="sm"
      color="#39BA9B"
      error-color="red"
      :hide-buttons="hideButtons"
    >
      <tab-content
        :title="$t('catalogWizard.tabsTitle.basicConfiguration')"
        icon="feather icon-settings"
        :before-change="validateAddCatalogStep"
      >
        <b-overlay :show="isLoading" rounded spinner-variant="primary" variants="transparent">
          <validation-observer ref="basicConfigurationValidationObserver">
            <AddCatalog ref="addCatalog" />
          </validation-observer>
        </b-overlay>
      </tab-content>
      <tab-content
        :title="$t('catalogWizard.tabsTitle.setMapping')"
        icon="feather icon-map"
        :before-change="validateMapDataChange"
      >
        <b-overlay :show="isLoading" rounded spinner-variant="primary" variants="transparent" z-index="400">
          <validation-observer ref="setMappingValidationObserver">
            <MapData />
          </validation-observer>
        </b-overlay>
      </tab-content>
      <tab-content
        :title="$t('catalogWizard.tabsTitle.catalogPreview')"
        icon="feather icon-search"
      >
        <b-overlay :show="isLoading" spinner-variant="primary" variants="transparent">
          <CatalogImportSummary />
        </b-overlay>
      </tab-content>
      <tab-content
        :title="$t('catalogWizard.tabsTitle.refreshSettings')"
        icon="feather icon-clock"
        :before-change="storeCatalogPeriodicData"
      >
        <b-overlay :show="isLoading" spinner-variant="primary" variants="transparent">
          <CatalogRefreshSettings ref="refreshSettings" />
        </b-overlay>
      </tab-content>
    </FormWizard>
  </vs-col>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import AddCatalog from '@/views/components/catalog/AddCatalog.vue'
import CatalogImportSummary from '@/views/components/catalog/CatalogImportSummary.vue'
import CatalogRefreshSettings from '@/views/components/catalog/CatalogRefreshSettings.vue'
import MapData from '@/views/components/catalog/MapData.vue'
import WizardErrors from '@/views/components/catalog/WizardErrors.vue'

import CatalogInstanceDetailsStore from '@/store/catalog/catalogInstanceDetails'

import store from '@/store'

export default {
  components: {
    WizardErrors,
    CatalogRefreshSettings,
    MapData,
    AddCatalog,
    CatalogImportSummary,
    FormWizard,
    TabContent,
  },
  computed: {
    isLoading() {
      return store.state.catalogWizard.isLoading
    },
    hideButtons() {
      return store.state.catalogWizard.hideButtons
    },
  },
  mounted() {
    store.dispatch('catalogWizard/loadFirstStep')
  },
  methods: {
    async validateAddCatalogStep() {
      return this.$refs.basicConfigurationValidationObserver.validate()
        .then(validationResult => {
          if (validationResult) {
            store.dispatch('catalogWizard/openSetMapping')
            return true
          }
          return false
        })
    },
    async storeCatalogPeriodicData() {
      this.$refs.refreshSettings.validate().then(result => {
        if (!result) {
          return
        }
        store.dispatch('catalogRefreshSettings/storeCatalogPeriodicData')
          .then(() => CatalogInstanceDetailsStore.dispatch('makeCatalogAvailableForProductionUse')
            .then(() => {
              this.$router.push({ name: 'catalogInstances' })
            }))
      })
    },
    async validateMapDataChange() {
      return this.$refs.setMappingValidationObserver.validate()
        .then(validationResult => {
          if (validationResult) {
            return store.dispatch('catalogWizard/open3rdStep')
          }
          return false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
