
















import Vue from 'vue'
import moduleCatalog from '@/store/catalog/moduleCatalog'
import moduleLanguages from '@/store/languages/moduleLanguages'

export default Vue.extend({
  name: 'SelectLanguage',
  computed: {
    chosenLanguage: {
      get(): string {
        return moduleCatalog.getters.getChosenLanguage
      },
      set(language: string) {
        moduleCatalog.commit('setChosenLanguage', language)
      },
    },
    getTranslatedLanguages() {
      const translatedLanguages = [
        { value: '', text: this.$t('catalog.language.chooseFromList') },
      ]
      const languages = moduleLanguages.getters.getLanguages

      Object.keys(languages).forEach(key => {
        const languageCode = languages[key].toLowerCase()
        const translationKey = `language.${languageCode}`
        translatedLanguages.push(
          { value: languageCode, text: this.$t(translationKey) },
        )
      })
      return translatedLanguages
    },
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
