
















import Vue from 'vue'
import moduleCatalog from '@/store/catalog/moduleCatalog'

export default Vue.extend({
  name: 'InputCatalogLink',
  computed: {
    catalogLink: {
      get(): string {
        return moduleCatalog.getters.getCatalogLink
      },
      set(catalogLink: string) {
        moduleCatalog.commit('setCatalogLink', catalogLink)
      },
    },
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
