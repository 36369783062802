















import Vue from 'vue'
import moduleCatalog from '@/store/catalog/moduleCatalog'
import moduleCurrencies from '@/store/currencies/moduleCurrencies'

export default Vue.extend({
  name: 'SelectCurrency',
  computed: {
    chosenCurrency: {
      get(): string {
        return moduleCatalog.getters.getChosenCurrency
      },
      set(currency: string) {
        moduleCatalog.commit('setChosenCurrency', currency)
      },
    },
    getCurrencies() {
      const currenciesResult = [
        { value: '', text: this.$t('catalog.currency.chooseFromList') },
      ]
      const currencies = moduleCurrencies.getters.getCurrencies
      Object.keys(currencies).forEach(key => {
        currenciesResult.push(
          { value: currencies[key], text: currencies[key] },
        )
      })
      return currenciesResult
    },
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
