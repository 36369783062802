

















import Vue from 'vue'
import moduleCatalog from '@/store/catalog/moduleCatalog'
import moduleIndustries from '@/store/industries/moduleIndustries'

export default Vue.extend({
  name: 'SelectIndustry',
  computed: {
    chosenIndustry: {
      get(): string {
        return moduleCatalog.getters.getChosenIndustry
      },
      set(industry: string) {
        moduleCatalog.commit('setChosenIndustry', industry)
      },
    },
    getTranslatedIndustries() {
      const translatedIndustries = [
        { key: 0, value: '', text: this.$t('catalog.industry.chooseFromList') },
      ]
      const industries = moduleIndustries.getters.getIndustries

      Object.keys(industries).forEach(key => {
        const translationKey = `industry.${industries[key]}`
        translatedIndustries.push(
          { key: Number(key), value: key, text: this.$t(translationKey) },
        )
      })
      return translatedIndustries
    },
  },
  methods: {
    getValidationState(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : null
    },
  },
})
