











import Vue from 'vue'
import SelectCurrency from './basicConfiguration/SelectCurrency.vue'
import ConfigurationHeader from './basicConfiguration/ConfigurationHeader.vue'
import InputCatalogName from './basicConfiguration/InputCatalogName.vue'
import SelectIndustry from './basicConfiguration/SelectIndustry.vue'
import SelectLanguage from './basicConfiguration/SelectLanguage.vue'
import InputCatalogLink from './basicConfiguration/InputCatalogLink.vue'

export default Vue.extend({
  name: 'AddCatalog',
  components: {
    SelectCurrency,
    InputCatalogLink,
    SelectLanguage,
    SelectIndustry,
    ConfigurationHeader,
    InputCatalogName,
  },
})
